<template>
  <div class="live-tv">
    <header class="main-header">
      <div class="row row-page-top flex-scroll">
        <h1 class="poster-rail--title section-title">TV en vivo</h1>

        <FiltersScroll :items="filterList" :widthControls="310">
          <div class="filters-toolbar">
            <Select
              cssClass="dropdown-channel"
              :list="channelsNameFilter"
              :onSelect="addFilter"
              :showFullLoader="showFullLoader"
              keyField="idChannel"
              textField="name"
              title="Canal"
              searchTitle="Filtrar por canal:"
              :filterList="filterList"
            />

            <Select
              cssClass="dropdown-order"
              :list="orderList"
              :onSelect="changeOrder"
              :showFullLoader="showFullLoader"
              keyField="orden"
              textField="texto"
              title="Orden"
              searchTitle="Ordenar por:"
              :showSearch="false"
              iconButton="zmdi-swap-vertical"
            />
          </div>
        </FiltersScroll>
      </div>
    </header>

    <main class="wrapper container-fluid virtual-scroll" role="main">
      <section class="row poster-grid">
        <template v-for="programa in programas">
          <CardTV
            v-if="programa && programa.status === 'PRESENTE'"
            :key="programa.idAsset"
            :data="programa"
            :showLive="false"
            componentOrigin="EPG"
            :sliderId="'tv-en-vivo'"
            :sliderTitle="'TV en vivo'"
            :cardId="programa.channel.id"
          />
        </template>
      </section>

      <LoaderScroll v-show="isScroll" />
    </main>

    <Loader v-if="showFullLoader" />
  </div>
</template>

<script>
const CANTIDAD_PAGINADO = 20;

export default {
  name: "TVLive",

  components: {
    CardTV: () => import("@/components/Card-TV.vue"),
    FiltersScroll: () => import("@/components/Filters-scroll.vue"),
    Loader: () => import("@/components/Loader.vue"),
    LoaderScroll: () => import("@/components/Loader-scroll.vue"),
    Select: () => import("@/components/Select.vue"),
  },

  data() {
    return {
      listChannel: [],
      programas: [],
      filters: {
        canales: [],
        canalesText: [],
        rango: {
          desde: 0,
          hasta: CANTIDAD_PAGINADO,
        },
        ordenTipos: {
          REL: "Orden: Grilla",
          ASC: "Orden: A-Z",
          DESC: "Orden: Z-A",
        },
        orden: "REL",
        indice: 0,
      },
      filterChannels: [],
      filterChannelsText: "",
      showFullLoader: true,
      isScroll: false,
    };
  },

  created() {
    let self = this;

    self.changeCursor(true);

    // Traigo el listado de canales
    telecentro.tplay.core.listChannel.obtenerCanales(function (ret) {
      self.listChannel = ret;

      telecentro.tplay.core.epg.eventoCambioProgramacion.on(
        self.onCambioProgramacion
      );

      self.moreChannels(function () {
        setTimeout(function () {
          self.showFullLoader = false;
          self.changeCursor(false);
        }, 250);
      });
    });
  },

  mounted() {
    let self = this;

    telecentro.tplay.core.epg.eventoCambioProgramacion.on(
      this.onCambioProgramacion
    );

    document.addEventListener("scroll", this.onScroll, true);

    this.$bus.$on("delete-filter-scroll", this.deleteFilter);
    this.$bus.$on("delete-filter", this.ClearFIlter);
  },

  beforeDestroy() {
    telecentro.tplay.core.epg.eventoCambioProgramacion.off(
      this.onCambioProgramacion
    );

    document.removeEventListener("scroll", this.onScroll, true);
    this.$bus.$off("delete-filter-scroll", this.deleteFilter);
    this.$bus.$off("delete-filter", this.ClearFIlter);
  },

  computed: {
    channelsNameFilter: function () {
      return this.findBy(
        this.listChannel,
        this.filterChannelsText.toUpperCase(),
        "name"
      );
    },

    //Muestro el filtro de orden siempre en la primera posición de los filtros
    filterList: function () {
      return [
        {
          name: this.filters.ordenTipos[this.filters.orden],
          canDelete: false,
        },
      ].concat(this.filterChannels);
    },

    orderList() {
      let ret = [];
      let lista = this.filters.ordenTipos;

      for (var i in lista) {
        if (lista.hasOwnProperty(i)) {
          ret.push({ orden: i, texto: lista[i] });
        }
      }

      return ret;
    },
  },

  methods: {
    changeCursor(show) {
      if (show) {
        document.documentElement.style.cursor = "wait";
      } else {
        document.documentElement.style.cursor = "default";
      }
    },

    findBy(list, value, column) {
      return list.filter(function (item) {
        let name = item[column].toUpperCase();
        return name.includes(value);
      });
    },

    clearChannels() {
      this.programas = [];
      this.filters.rango.desde = 0;
      this.filters.rango.hasta = CANTIDAD_PAGINADO;
      this.filters.indice = 0;
    },

    onCambioProgramacion(lcn, data) {
      for (let i = 0; i < this.programas.length; i++) {
        if (this.programas[i].channel.lcn == lcn) {
          this.$nextTick(function () {
            this.programas[i] = data;
            this.$forceUpdate();
          });

          break;
        }
      }
    },

    moreChannels(onFinish) {
      let self = this;

      telecentro.tplay.core.programacionActual.obtenerProgramacionActual(
        self.filters,
        function (ret) {
          self.programas = self.programas.concat(ret);

          self.filters.rango.desde = self.filters.rango.hasta;
          self.filters.rango.hasta =
            self.filters.rango.desde + CANTIDAD_PAGINADO;

          self.$nextTick(function () {
            if (onFinish) {
              onFinish();
            }
          });
        }
      );
    },

    onScroll() {
      if (!this.isScroll && this.filterChannels.length <= 0) {
        function getScrollPercent() {
          return (
            ((document.documentElement.scrollTop + document.body.scrollTop) /
              (document.documentElement.scrollHeight -
                document.documentElement.clientHeight)) *
            100
          );
        }

        if (getScrollPercent() >= 90) {
          this.isScroll = true;

          document.body.style.pointerEvents = "none";

          this.changeCursor(true);

          let self = this;

          setTimeout(function () {
            self.moreChannels(function () {
              setTimeout(function () {
                self.isScroll = false;

                document.body.style.pointerEvents = "";

                self.changeCursor(false);
              }, 100);
            });
          }, 250);
        }
      }
    },

    addFilter(channel) {
      let self = this;

      self.showFullLoader = true;
      self.changeCursor(true);

      let exist = self.filters.canales.find(function (tmpItem) {
        return tmpItem === channel.idChannel;
      });

      let fin = function () {
        setTimeout(function () {
          self.showFullLoader = false;
          self.changeCursor(false);
        }, 250);
      };

      if (exist) {
        fin();
        return;
      }

      self.clearChannels();

      this.$nextTick(function () {
        self.filters.canales.push(parseInt(channel.idChannel));

        self.moreChannels(function () {
          self.filterChannels.push({
            idChannel: channel.idChannel,
            name: channel.name,
            key: "channel:" + channel.idChannel,
            canDelete: true,
          });

          fin();
        });
      });
    },

    ClearFIlter(flag) {
      let self = this;

      self.showFullLoader = true;

      self.changeCursor(true);

      self.clearChannels();

      self.filters.orden = "REL";
      self.filters.canales.length = 0;
      self.filterChannels.length = 0;
      self.filterChannels = [];

      self.$nextTick(function () {
        self.moreChannels(function () {
          setTimeout(function () {
            self.showFullLoader = false;

            self.changeCursor(false);
          }, 250);
        });
      });
    },

    deleteFilter(index, item) {
      let self = this;

      self.showFullLoader = true;

      self.changeCursor(true);

      self.clearChannels();

      //Busco el canal por idChannel y lo elimino del array
      for (let i = 0; i < self.filters.canales.length; i++) {
        if (self.filters.canales[i] === item.idChannel) {
          self.filters.canales.splice(i, 1);
          break;
        }
      }

      //Busco el canal por idChannel y lo elimino del array
      for (let i = 0; i < self.filterChannels.length; i++) {
        if (self.filterChannels[i].idChannel === item.idChannel) {
          self.filterChannels.splice(i, 1);
          break;
        }
      }

      self.$nextTick(function () {
        self.moreChannels(function () {
          setTimeout(function () {
            self.showFullLoader = false;

            self.changeCursor(false);
          }, 250);
        });
      });
    },

    changeOrder(orden) {
      let self = this;

      self.showFullLoader = true;

      self.changeCursor(true);

      self.clearChannels();

      self.filters.orden = orden.orden;

      self.$nextTick(function () {
        self.moreChannels(function () {
          setTimeout(function () {
            self.showFullLoader = false;

            self.changeCursor(false);
          }, 250);
        });
      });
    },
  },
};
</script>
